const colors = {
  primary: "#ea3ba1",
  secondary: "#393e46",
  lightPink: "#FDF4FA",
  facebook: "#4267b2",
  instagram: "#661b77",
  youtube: "red",
}

export default colors
